import { sendGetRequest, sendPostRequest, sendPutRequest, sendDeleteRequest } from '../helpers';

const baseApiUrl = `${process.env.API_URL}/feed`;

export const feedService = {
  getPostList,
  getPostComments,
  getPostMentions,

  createPost,
  createPostComment,

  setPostAbuse,
  setPostLike,
  setPostCommentAbuse,
  setPostHide,
  setPostUnhide,
  setPostCommentHide,
  setPostCommentUnhide,

  deletePost,
  deletePostLike,
  deletePostComment
};

// get posts
function getPostList(page = 1, limit = 10) {
  return sendGetRequest(baseApiUrl, { page, limit })
    .then(response => {
      return response.data.result;
    })
    .catch(error => console.error(error));
}

// get comments of the Post
function getPostComments(postId) {
  return sendGetRequest(`${baseApiUrl}/${postId}`)
    .then(response => {
      return response.data.result;
    })
    .catch(error => console.error(error));
}

// find users by search criteria
function getPostMentions(term, limit = 10) {
  return sendGetRequest(`${baseApiUrl}/users/search`, { term, limit })
    .then(response => {
      return response.data.result;
    })
    .catch(error => console.error(error));
}

// create a post
function createPost(text, image = null) {
  let formData = new FormData();
  formData.append('html', text);

  if (image != null) {
    formData.append('image', image);
  }

  return sendPostRequest(baseApiUrl, formData);
}

// create a Post Comment
function createPostComment(postId, text) {
  return sendPostRequest(`${baseApiUrl}/${postId}`, { 
    html: text
  })
  .catch(error => console.error(error));
}

// delete the Post
function deletePost(postId) {
  return sendDeleteRequest(`${baseApiUrl}/${postId}`)
    .catch(error => console.error(error));
}

// report abuse for the Post
function setPostAbuse(postId) {
  return sendPutRequest(`${baseApiUrl}/${postId}/abuse`)
    .catch(error => console.error(error));
}

// like the Post
function setPostLike(postId) {
  return sendPutRequest(`${baseApiUrl}/${postId}/like`)
    .catch(error => console.error(error));
}

// delete your Like from the Post
function deletePostLike(postId) {
  return sendDeleteRequest(`${baseApiUrl}/${postId}/like`)
    .catch(error => console.error(error));
}

// report abuse for the Comment
function setPostCommentAbuse(postId, commentId) {
  return sendPutRequest(`${baseApiUrl}/${postId}/${commentId}/abuse`)
    .catch(error => console.error(error));
}

// hide the Post
function setPostHide(postId) {
  return sendPutRequest(`${baseApiUrl}/${postId}/hide`)
    .catch(error => console.error(error));
}

// un-hide the Post
function setPostUnhide(postId) {
  return sendPutRequest(`${baseApiUrl}/${postId}/un-hide`)
    .catch(error => console.error(error));
}

// hide the Post Comment
function setPostCommentHide(postId, commentId) {
  return sendPutRequest(`${baseApiUrl}/${postId}/${commentId}/hide`)
    .catch(error => console.error(error));
}

// un-hide the Post Comment
function setPostCommentUnhide(postId, commentId) {
  return sendPutRequest(`${baseApiUrl}/${postId}/${commentId}/un-hide`)
    .catch(error => console.error(error));
}

// delete the Post Comment
function deletePostComment(postId, commentId) {
  return sendDeleteRequest(`${baseApiUrl}/${postId}/${commentId}`)
    .catch(error => console.error(error));
}

// function editLiveClassCourse({ courseId, data }) {
//   let formData = new FormData();
//   for (let prop in data) {
//     if (data[prop]) {
//       formData.append(prop, data[prop]);
//     }
//   }
//   return sendPostRequest(
//     `${process.env.API_URL}/live-class/${courseId}/edit`,
//     formData
//   );
// }
// function downloadLive({ courseId, liveClassId }) {
//   return sendPostRequest(`${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/download`);
// }

// function getUploadUrl({ courseId, liveClassId, format }) {
//   return sendPostRequest(`${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/get-upload-url`, { format });
// }
