import { sendGetRequest, sendPostRequest } from '../helpers';

export const liveClassesService = {
  findLiveClassCourses,
  fetchLiveClassCourse,
  fetchRelatedRecorded,
  fetchLiveClass,
  secretValidate,
  addToBookmarks,
  createLiveClassCourse,
  createLiveClass,
  editLiveClassCourse,
  editLiveClass,
  removeLiveClassCourse,
  removeLiveClass,
  deactivateLiveClassCourse,
  deactivateLiveClass,
  startLive,
  publishLiveDetails,
  goLive,
  pauseLive,
  resumeLive,
  stopLive,
  downloadLive,
  getUploadUrl,
  liveUploadDone,
  connectLive,
  watchLive,
  sendMessage,
  publishLive,
  reviewLive,
  setLiveWatched,
  getMessages,
  getVideoAssets,
  getClassesInstructors
};

function findLiveClassCourses(params) {
  return sendGetRequest(`${process.env.API_URL}/live-class/find`, params)
    .then((response) => {
      return response.data.result;
    })
    .catch((error) => console.log(error));
}

function fetchLiveClassCourse({ courseId, search, isLiveMenu, secret }) {
  let url = `${process.env.API_URL}/live-class/${courseId}`;
  if (!search) {
    if (isLiveMenu) {
      url += '?live_and_future=1';
    } else {
      url += '?archive_only=1';
    }  
  }

  return sendGetRequest(url, {
    secret
  }).then((response) => {
    return response.data.result.live_class;
  });
}

function fetchLiveClass({ courseId, liveClassId }) {
  return sendGetRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}`
  )
    .then((response) => {
      return response.data.result.live_class_video;
    })
    .catch((error) => console.log(error));
}

function fetchRelatedRecorded({ courseId }) {
  return sendGetRequest(
    `${process.env.API_URL}/live-class/${courseId}/related-recorded-classes`
  ).then((response) => {
    return response.data.result.recorded_classes;
  });
}

function secretValidate({ courseId, secret }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/secret-validate`,
    { secret }
  );
}

function addToBookmarks(data) {
  return sendPostRequest(
    `${process.env.API_URL}/favorite/save`,
    data
  ).catch((error) => console.log(error));
}

function createLiveClassCourse(data) {
  let formData = new FormData();
  for (let prop in data) {
    if (data[prop]) {
      formData.append(prop, data[prop]);
    }
  }
  return sendPostRequest(`${process.env.API_URL}/live-class/add`, formData);
}

function createLiveClass({ courseId, data }) {
  let formData = new FormData();
  for (let prop in data) {
    //if (data[prop]) {
      formData.append(prop, data[prop]);
    //}
  }
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/add`,
    formData
  );
}

function editLiveClassCourse({ courseId, data }) {
  let formData = new FormData();
  for (let prop in data) {
    if (data[prop]) {
      formData.append(prop, data[prop]);
    }
  }
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/edit`,
    formData
  );
}

function editLiveClass({ courseId, data, liveClassId }) {
  let formData = new FormData();
  for (let prop in data) {
    //if (data[prop]) {
      formData.append(prop, data[prop]);
    //}
  }
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/edit`,
    formData
  );
}

function removeLiveClassCourse({ courseId }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/remove`
  );
}

function removeLiveClass({ courseId, liveClassId }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/remove`
  );
}

function deactivateLiveClassCourse({ courseId, deactivated }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/deactivate`,
    { status: deactivated }
  );
}

function deactivateLiveClass({ courseId, liveClassId, deactivated }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/deactivate`,
    { status: deactivated }
  );
}

function startLive({ courseId, liveClassId }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/start`
  );
}

function publishLiveDetails({ courseId, liveClassId }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/publish-details`
  );
}

function goLive({ courseId, liveClassId }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/go-live`
  );
}

function pauseLive({ courseId, liveClassId, minutes }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/pause`,
    { minutes }
  );
}

function resumeLive({ courseId, liveClassId }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/pause-complete`
  );
}

function stopLive({ courseId, liveClassId }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/stop`
  );
}

function downloadLive({ courseId, liveClassId }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/download`
  );
}

function getUploadUrl({ courseId, liveClassId, format }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/get-upload-url`,
    { format }
  );
}

function liveUploadDone({ courseId, liveClassId, id }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/upload-done`,
    { id }
  );
}

function connectLive({ courseId, liveClassId }) {
  return sendGetRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/connect`
  );
}

function watchLive({ courseId, liveClassId }) {
  return sendGetRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/watch`
  );
}

function sendMessage({ courseId, liveClassId, message }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/message`,
    { message }
  );
}

function publishLive({ courseId, liveClassId }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/complete`
  );
}

function reviewLive({ courseId, liveClassId, rating, review, secret }) {
  return sendPostRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/review`,
    { rating, review, secret }
  );
}

function setLiveWatched({ courseId, liveClassId }) {
  return sendGetRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/mark-as-watched`
  );
}

function getMessages({ courseId, liveClassId }) {
  return sendGetRequest(
    `${process.env.API_URL}/live-class/${courseId}/video/${liveClassId}/messages`
  );
}

function getVideoAssets() {
  return sendGetRequest(`${process.env.API_URL}/video-assets`);
}

function getClassesInstructors(categoryId) {
  return sendGetRequest(`${process.env.API_URL}/classes-categories/${categoryId}/instructors`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => console.log(error));
}
