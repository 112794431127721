<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      class="modal-mask"
      v-if="show"
      @click="close"
      :style="maskStyle"
    >
      <div
        class="modal-container"
        :class="theme"
        @click.stop
        :style="modalStyle"
      >
        <div
          class="close-button"
          @click="close"
          v-if="closeButton"
        >
          <img :src="closeButtonSrc" />
        </div>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean
    },
    closeButton: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '600px'
    },
    theme: {
      type: String,
      default: 'dark'
    },
    fixed: {
      type: Boolean,
      default: false
    },
    modalNumber: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      marginTop: window.pageYOffset
    };
  },
  methods: {
    close() {
      this.$emit('close');
    }
  },
  updated() {
    this.marginTop = window.pageYOffset;
  },
  computed: {
    winHeight() {
      return this.fixed
        ? '100vh'
        : Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight
          ) + 'px';
    },
    maskStyle() {
      return {
        height: this.winHeight,
        position: this.fixed ? 'fixed' : 'absolute',
        zIndex: 2000 + 100 * this.modalNumber
      };
    },
    modalStyle() {
      return {
        marginTop: !this.fixed ? `${this.marginTop + 30}px` : '',
        width: `${this.width}`,
        top: this.fixed ? '50%' : '',
        transform: this.fixed ? 'translateY(-50%)' : ''
      };
    },
    closeButtonSrc() {
      return this.theme === 'light'
        ? '/img/icons/close_white.svg'
        : '/img/icons/close_pink.svg';
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-mask {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}
.modal-container {
  padding: 25px;
  margin: 0 auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.24), 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
  position: relative;
  box-sizing: border-box;

  &.dark {
    background-color: #2d1d3f;
  }
  &.light {
    background-color: #584a74;
  }
  &.white {
    background-color: #ffffff;
  }
  &.black {
    background-color: #160028;
  }
}
.modal-container.full-screen-modal {
  position: fixed;
  height: 100%;
  overflow-y: auto;
}
.close-button {
  cursor: pointer;
  position: absolute;
  right: 25px;
  z-index: 10;
}

@media (max-width: 600px) {
  .modal-container {
    padding: 25px 10px;
  }
  .close-button {
    width: 16px;
    height: 16px;
    box-shadow: none;
    // left: 20px;
  }
}
@media only screen and (max-width: 512px) {
  .modal-container {
    width: 100vw;
    height: auto;
  }
}
</style>
