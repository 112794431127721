import { handleResponse, logoutBase } from './main.service';

import {
  authHeader,
  setProfile,
  setToken,
  setOAuthToken,
  sendRequest,
  sendPostRequest,
  sendGetRequest
} from '../helpers';

import { VERSION } from '../helpers/constants'

export const userService = {
  login,
  signUp,
  signUpPromo,
  loginProvider,
  logout,
  register,
  getProfile,
  getPaymentStatus,
  getNotifications,
  getUnreadNotifications,
  getWebSocketToken,
  getDictionary,
  update,
  forgotPassword,
  resetPassword,
  changePassword,
  getHelp,
  verifyEmail,
  validatePromoCode
};

function login(email, password, deviseOS) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      password,
      device_os: deviseOS,
      app_version: VERSION
    })
  };

  return sendRequest(`${process.env.API_URL}/auth/login`, requestOptions)
    .then(handleResponse)
    .then(async (user) => {
      if (user.success === true) {
        setToken(user.result);

        return setProfile(await this.getProfile());
      }
    });
}

function signUp(email, password, deviseOS) {
  const requestOptions = {
    method: 'post',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: email,
      password: password,
      device_os: deviseOS,
      app_version: VERSION
    })
  };

  return sendRequest(`${process.env.API_URL}/auth/sign-up`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      if (user.success === true) {
        setToken(user.result);

        return this.getProfile();
      }
    });
}

function signUpPromo(email, password, deviseOS) {
  const requestOptions = {
    method: 'post',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: email,
      password: password,
      device_os: deviseOS,
      app_version: VERSION
    })
  };

  return sendRequest(`${process.env.API_URL}/auth/sign-up-promo`, requestOptions)
      .then(handleResponse)
      .then((user) => {
        if (user.success === true) {
          setToken(user.result);

          return this.getProfile();
        }
      });
}

async function loginProvider(token) {
  setToken({
    token: { token }
  });
  return setProfile(await this.getProfile());
}

function forgotPassword(email) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email: email })
  };

  return sendRequest(
    `${process.env.API_URL}/auth/forgot-password`,
    requestOptions
  ).then(handleResponse);
}

function resetPassword(email, password, token) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: email,
      password: password,
      token: token
    })
  };

  return sendRequest(
    `${process.env.API_URL}/auth/reset-password`,
    requestOptions
  ).then(handleResponse);
}

function logout() {
  logoutBase();
}

function register({ first_name, last_name, email, password, device_os, app_version }) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      first_name,
      last_name,
      email,
      password,
      device_os,
      app_version
    })
  };

  return sendRequest(`${process.env.API_URL}/auth/register`, requestOptions)
    .then(handleResponse)
    .then(async (user) => {
      if (user.success === true) {
        setToken(user.result);

        return setProfile(await this.getProfile());
      }
    });
}

function getProfile() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return sendRequest(`${process.env.API_URL}/user/me`, requestOptions).then(
    handleResponse
  );
}

function getPaymentStatus() {
  return sendGetRequest(`${process.env.API_URL}/payment/status`).then(
    (response) => response.data.result
  );
}

function getWebSocketToken() {
  return sendGetRequest(`${process.env.API_URL}/auth/websocket-token`).then(
      (response) => response.data.result
  );
}

function validatePromoCode(code, plan_id) {
  let formData = new FormData();
  formData.append('code', code);
  formData.append('plan_id', plan_id);
  return sendPostRequest(
      `${process.env.API_URL}/payment/coupon/validate`,
      formData
  ).then((response) => response.data.result);
}

function getDictionary() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return sendRequest(
    `${process.env.API_URL}/dictionary/all`,
    requestOptions
  ).then(handleResponse);
}

function update({
  firstName,
  lastName,
  location,
  cityString,
  birthday,
  photo,
  gender,
  bio,
  country,
  state,
  firstNameSub,
  lastNameSub,
  locationSub,
  birthdaySub,
  photoSub,
  genderSub,
  profLevel
}) {
  //AFTER PHOTO UPLOAD RESTORE PUT CODE FROM USERS.SERVICE HERE
  let formData = new FormData();
  if (photo !== null && photo !== undefined) {
    formData.append('photo', photo);
  }
  if (photoSub !== null && photoSub !== undefined) {
    formData.append('photo_subprofile', photoSub);
  }
  if (firstName) {
    formData.append('first_name', firstName);
  }
  if (lastName) {
    formData.append('last_name', lastName);
  }
  if (location) {
    formData.append('city', location);
  }
  if (birthday) {
    formData.append('dob', birthday);
  }

  if (gender) {
    formData.append('gender', gender);
  }
  if (bio) {
    formData.append('bio', bio);
  }
  if (firstNameSub) {
    formData.append('first_name_subprofile', firstNameSub);
  }
  if (lastNameSub) {
    formData.append('last_name_subprofile', lastNameSub);
  }
  if (birthdaySub) {
    formData.append('dob_subprofile', birthdaySub);
  }
  if (genderSub) {
    formData.append('gender_subprofile', genderSub);
  }
  if (locationSub) {
    formData.append('city_subprofile', locationSub);
  }

  if (profLevel) {
    formData.append('proficiency_level', profLevel);
  }
  if (cityString) {
    formData.append('city_string', cityString);
  }
  if (country) {
    formData.append('country', country);
  }
  if (state) {
    formData.append('state', state);
  }
  const requestOptions = {
    method: 'post',
    headers: { ...authHeader() },
    body: formData
  };

  return sendRequest(`${process.env.API_URL}/user/me`, requestOptions).then(
    handleResponse
  );
}

function changePassword(currentPassword, newPassword) {
  const requestOptions = {
    method: 'post',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      old_password: currentPassword,
      new_password: newPassword
    })
  };

  return sendRequest(
    `${process.env.API_URL}/auth/change-password`,
    requestOptions
  ).then(handleResponse);
}

function getHelp({ subject, message }) {
  const requestOptions = {
    method: 'post',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      subject,
      message
    })
  };

  return sendRequest(`${process.env.API_URL}/help`, requestOptions).then(
    handleResponse
  );
}

function verifyEmail(token) {
  return sendPostRequest(`${process.env.API_URL}/auth/verify-email`, { token });
}

function getNotifications({ page }) {
  return sendGetRequest(
    `${process.env.API_URL}/notification?page=${page}&limit=6`
  );
}

function getUnreadNotifications() {
  return sendGetRequest(`${process.env.API_URL}/notification/unread`);
}
