import moment from 'moment';
import { userService } from '../services';
import {router, sendGetRequest, setProfile, validateFields} from '../helpers';
import { logoutBase } from '../services/main.service';
import * as firebase from "firebase/app";
import "firebase/analytics";

const user = JSON.parse(localStorage.getItem('user'));
// const paymentStatus = JSON.parse(localStorage.getItem('payment-status'));

const isAdminDomain = window.location.host
  .split('.')
  .some((part) => /^admin$/.test(part));

// const isAdminDomain = true;

let state = {
  user,
  status: {
    loggedIn: user ? true : false
  },
  isAdminDomain,
  dictionary: {},
  paymentStatus: null,
  notifications: [],
  notificationsPage: 1,
  unreadNotifications: null,
  totalNotifications: null
};

const actions = {
  login({ getters, dispatch, commit }, { email, password }) {
    commit('loginRequest', { email });

    return userService.login(email, password, getters.deviseOS).then(
      (user) => {
        commit('loginSuccess', user);
        let afterLoginLink;
        switch (user.role_id) {
          case 1:
            afterLoginLink = 'dashboard';
            break;
          case 2:
            afterLoginLink = 'classesHome';
            break;
          case 3:
            afterLoginLink = 'classesHome';
            break;
        }
        user.role_id === 2 && dispatch('getPaymentStatus');
        dispatch('getNotifications');
        dispatch('getUnreadNotifications');
        dispatch('webSocketConnect');

        router.push({
          name: afterLoginLink
        });
      },
      (error) => {
        commit('loginFailure', error);
        if (user.status === 'invited' || user.deactivate) {
          logoutBase().then(function() {
            router.push({
              name: 'forbidden'
            });
          });
        } else {
          dispatch('alert/error', error, { root: true });
        }
      }
    );
  },
  loginPromo({ getters, dispatch, commit }, { email, password }) {
    commit('loginRequest', { email });
    commit('ui/startRouteLoading', null, { root: true });
    return userService.login(email, password, getters.deviseOS).then(
      (user) => {
        commit('loginSuccess', user);
      },
      (error) => {
        commit('loginFailure', error);
        if (user.status === 'invited' || user.deactivate) {
          logoutBase();
        } else {
          dispatch('alert/error', error, { root: true });
        }
      }
    ).finally(() => {
      commit('ui/endRouteLoading', null, { root: true });
    });
  },
  loginProvider({ commit, dispatch }, token) {
    return userService
      .loginProvider(token)
      .then((user) => {
        commit('loginSuccess', user);
        dispatch('getPaymentStatus');
        dispatch('getUnreadNotifications');
        dispatch('getNotifications');
        firebase.analytics().logEvent('login', {method: 'web'});
        router.push({
          name: 'classesHome'
        });
      })
      .catch((error) => {
        commit('loginFailure', error);
      });
  },
  loginProviderPromo({ commit, dispatch }, token) {
    commit('ui/startRouteLoading', null, { root: true });
    return userService
      .loginProvider(token)
      .then((user) => {
        commit('loginSuccess', user);
      })
      .catch((error) => {
        commit('loginFailure', error);
      }).finally(() => {
        commit('ui/endRouteLoading', null, { root: true });
      });
  },
  signUp(
    { dispatch, commit, getters },
    { email, newPassword, confirmPassword }
  ) {
    let errMsg = '';
    if (newPassword !== confirmPassword) {
      errMsg = 'Confirm password error';
      dispatch('alert/error', errMsg, { root: true });
      throw errMsg;
    } else {
      commit('loginRequest', { email });
      return userService.signUp(email, newPassword, getters.deviseOS).then(
        (data) => {
          fbq('track', 'CompleteRegistration');
          dispatch('alert/success', 'User has signup', { root: true });
          setProfile(data);
          commit('loginSuccess', data.result.user);
          if (getters.isAdmin) {
            router.push({ name: 'dashboard' });
          } else if (getters.isInstructor) {
            router.push({ name: 'instructorProfile' });
          } else {
            router.push({ name: 'userProfile' });
          }
        },
        (error) => {
          commit('loginFailure', error);
          dispatch('alert/error', error, { root: true });
          throw error;
        }
      );
    }
  },
  signUpPromo({ dispatch, commit, getters }, { email, password }) {
    commit('loginRequest', { email });
    commit('ui/startRouteLoading', null, { root: true });
    return userService.signUpPromo(email, password, getters.deviseOS).then(
      (data) => {
        fbq('track', 'CompleteRegistration');
        setProfile(data);
        commit('loginSuccess', data.result.user);
      },
      (error) => {
        commit('loginFailure', error);
        dispatch('alert/error', error, { root: true });
        throw error;
      }).finally(() => {
        commit('ui/endRouteLoading', null, { root: true });
      });
  },
  async logout({ commit }) {
    await userService.logout();
    commit('logout');
  },
  register({ dispatch, commit }, user) {
    commit('registerRequest');

    userService.register(user).then(
      (user) => {
        commit('registerSuccess', user);
        user.role_id === 2 && dispatch('getPaymentStatus');
        fbq('track', 'CompleteRegistration');
        firebase.analytics().logEvent('sign_up', {method: 'web'});
        router.push({ name: 'main' });
      },
      (error) => {
        commit('registerFailure', error);
      }
    );
  },
  forgotPassword({ dispatch, commit }, { email }) {
    commit('updateRequest');
    userService.forgotPassword(email).then(
      (success) => {
        commit('updateSuccess');
        router.push({ name: 'login' });
        dispatch('alert/success', 'Please check your email', { root: true });
        localStorage.setItem('email', email);
      },
      (error) => {
        commit('updateFailure');
        dispatch('alert/error', error, { root: true });
        router.push({ name: 'forgotPassword' });
      }
    );
  },
  resetPassword({ dispatch, commit }, { email, password, token }) {
    commit('updateRequest');
    userService.resetPassword(email, password, token).then(
      (success) => {
        commit('updateSuccess');
        localStorage.removeItem('email');
        router.push({ name: 'login' });
        dispatch('alert/success', 'New password was set', { root: true });
      },
      (error) => {
        commit('updateFailure');
        dispatch('alert/error', error, { root: true });
        router.push({ path: '/auth/reset-password?token=' + token });
      }
    );
  },
  getProfile({ dispatch, commit }) {
    return userService.getProfile().then(
      async (user) => {
        return user.result.user;
      },
      (error) => {
        dispatch('alert/error', error, { root: true });
        throw error;
      }
    );
  },
  async getDictionary({ commit }) {
    let dictionary = await userService.getDictionary();
    if (
      state.user &&
      getters.isInstructor &&
      dictionary &&
      dictionary.result &&
      dictionary.result.instructors
    ) {
      dictionary.result.instructors = dictionary.result.instructors.sort(
        (a, b) => {
          return a.id === state.user.id ? -1 : 1;
        }
      );
    }

    commit('setDictionary', dictionary);

    return dictionary;
  },
  updateProfile({ dispatch, commit }, params) {
    commit('updateRequest');
    return userService.update(params).then(
      async (success) => {
        commit('updateSuccess');
        dispatch('alert/success', 'Profile was updated', { root: true });
      },
      (error) => {
        commit('updateFailure');
        dispatch('alert/error', error, { root: true });
        throw error;
      }
    );
  },
  deletePhoto({ dispatch, commit }) {
    return userService.update({ photo: '' }).then(
      async (success) => {
        state.user = setProfile(await userService.getProfile());
        dispatch('alert/success', 'Photo was deleted', { root: true });
      },
      (error) => {
        dispatch('alert/error', error, { root: true });
      }
    );
  },
  changePassword(
    { dispatch, commit },
    { currentPassword, newPassword, confirmPassword }
  ) {
    let errMsg = '';
    if (!currentPassword) {
      validateFields({ old_password: 'Field is empty' });
      throw errMsg;
    } else if (!newPassword) {
      validateFields({ new_password: 'Field is empty' });
      throw errMsg;
    } else if (!confirmPassword) {
      validateFields({ confirm_password: 'Field is empty' });
      throw errMsg;
    } else if (newPassword !== confirmPassword) {
      errMsg = 'Confirm password error';
      dispatch('alert/error', errMsg, { root: true });
      throw errMsg;
    } else {
      commit('updateRequest');
      return userService.changePassword(currentPassword, newPassword).then(
        (success) => {
          commit('updateSuccess');
          dispatch('alert/success', 'Password was updated', { root: true });
        },
        (error) => {
          commit('updateFailure');
          dispatch('alert/error', error, { root: true });
          throw error;
        }
      );
    }
  },
  getPaymentStatus({ commit }) {
    userService.getPaymentStatus().then((status) => {
      commit('setPaymentStatus', status);

      console.log(1);
      const liveRoute = 'liveClassCourses';
      const sessionKey = 'subscription-missing';
      if (status.subscribed === true && sessionStorage.getItem('thankyou') == 1) {
        console.log(6);
        sessionStorage.removeItem('thankyou');
        return;
      }
      if (status.subscribed === true || sessionStorage.getItem(sessionKey) == 1) {
        console.log(2);
        return;
      }

      console.log(3);
      // open subscription modal, if it hasn't been yet
      const openSubscriptionPopup = () => {
        console.log(4);
        commit('ui/openSubscription', null, { root: true });
        sessionStorage.setItem(sessionKey, 1);
      };

      console.log(5);
      // redirect to live classes, if payment status is not subscribed
      if (router.currentRoute.name !== liveRoute) {
        console.log(7);
        router.replace({ name: liveRoute }, openSubscriptionPopup);
      } else {
        console.log(8);
        openSubscriptionPopup();
      }
      console.log(9);
    });
  },
  getPaymentStatusPromo({ commit }) {
    return userService.getPaymentStatus().then((status) => {
      commit('setPaymentStatus', status);
    });
  },
  getNotifications({ state, commit }) {
    return userService
      .getNotifications({ page: state.notificationsPage })
      .then((response) => {
        commit('setNotifications', response.data.result.notifications);
        commit('setTotalNotifications', response.data.result.total);
        return response.data.result.notifications;
      });
  },
  getUnreadNotifications({ commit }) {
    userService.getUnreadNotifications().then((response) => {
      commit('setUnreadNotifications', response.data.result.unread);
    });
  },
  webSocketConnect() {
    // todo: temp solution
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    userService.getWebSocketToken().then((response) => {
      this.websocketToken = response.websocket_token;
      this._vm.$socket.client.io.opts.query = {
        token: this.websocketToken
      };
      this._vm.$socket.client.open();
    });
  }
};

const mutations = {
  setDictionary(state, dictionary = {}) {
    state.dictionary = dictionary;
  },
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = { loggingIn: false };
    state.status = {};
    state.user = null;
  },
  logout(state, reload = true) {
    sessionStorage.clear();
    localStorage.removeItem('user');
    localStorage.removeItem('token');

    state.user = null;
    state.status.loggedIn = false;
    state.paymentStatus = null;
    state.isAdminDomain = false;

    if (reload) {
      location.reload(true);
    }
  },
  registerRequest(state) {
    state.status = { registering: true };
  },
  registerSuccess(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  registerFailure(state) {
    state.status = {};
  },
  updateRequest(state) {
    state.status = { loading: true };
  },
  async updateSuccess(state) {
    let user = setProfile(await userService.getProfile());
    state.user = user;
    state.status = {};
  },
  updateFailure(state) {
    state.status = {};
  },
  setPaymentStatus(state, status) {
    state.paymentStatus = status;
    // localStorage.setItem('payment-status', JSON.stringify(status));
  },
  setNotifications(state, notifications) {
    state.notifications = state.notifications.concat(notifications);
  },
  addNewNotification(state, notification) {
    state.notifications.splice(0, 0, notification);
  },
  setLastNotifications(state) {
    state.notifications = state.notifications.splice(0, 6);
  },
  setTotalNotifications(state, total) {
    state.totalNotifications = total;
  },
  setUnreadNotifications(state, count) {
    state.unreadNotifications = count;
  },
  nextNotificationsPage(state) {
    state.notificationsPage++;
  },
  markNotificationAsRead(state, id) {
    state.notifications.find(
      (notification) => notification.id === id
    ).read_at = true;
  }
};

const getters = {
  deviseOS(state) {
    return state.isAdminDomain ? 'web_admin' : 'web';
  },
  loggedIn(state) {
    return !!state.user;
  },
  isAdmin(state) {
    return state.user && state.user.role_id === 1;
  },
  isUser(state) {
    return state.user && state.user.role_id === 2;
  },
  isInstructor(state) {
    return state.user && state.user.role_id === 3;
  },
  userId(state) {
    return state.user && state.user.id;
  },
  fullName(state) {
    return state.user.first_name + ' ' + state.user.last_name;
  },
  fullNameSub(state) {
    return (
      state.user.subprofile.first_name + ' ' + state.user.subprofile.last_name
    );
  },
  gender(state) {
    return state.user.gender ? state.user.gender.name : null;
  },
  genderSub(state) {
    return state.user.subprofile.gender
      ? state.user.subprofile.gender.name
      : null;
  },
  profLevel(state) {
    return state.user.proficiency_level
      ? state.user.proficiency_level.name
      : null;
  },
  location(state) {
    if (!state.user.city) {
      return '';
    } else {
      const stateName = state.dictionary.result.states.find(
        (s) => s.id === state.user.city.state_id
      ).name;
      return `${state.user.city.name}, ${stateName}`;
    }
  },
  locationSub(state) {
    if (!state.user.subprofile.city) {
      return '';
    } else {
      const stateName = state.dictionary.result.states.find(
        (s) => s.id === state.user.subprofile.city.state_id
      ).name;
      return `${state.user.subprofile.city.name}, ${stateName}`;
    }
  },
  birthday(state) {
    return state.user.dob ? moment(state.user.dob).format('L') : null;
  },
  birthdaySub(state) {
    return state.user.subprofile.dob
      ? moment(state.user.subprofile.dob).format('L')
      : null;
  },
  isSingleInstructor(state) {
    return !state.user.subprofile;
  },
  getCategoryById: (state) => (id) => {
    return state.dictionary.result.categories.find(el => el.id === parseInt(id));
  },
  getInstructorById: (state) => (id) => {
    return state.dictionary.result.instructors.find(el => el.id === parseInt(id));
  }
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
