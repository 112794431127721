import {
  authHeader, sendPostRequest,
  sendRequest
} from '../helpers';

import {
  handleResponse,
  prepareGetRequest,
  preparePostRequest,
  preparePutRequest
} from './main.service';

export const recClassesService = {
  add,
  updateClass,
  generateVideoS3,
  getClassesCategories,
  getClass,
  getUpcomingLiveClasses,
  fetchRecordedClasses,
  deactivateOrActivate,
  delete: _delete,
  deleteMove,
  publish,
  updateMove,
  swapOrder
};

function add(body) {
  return sendRequest(`${process.env.API_URL}/classes`, preparePostRequest(body)).then(
      handleResponse
  );
}

function updateClass(id, body) {
  return sendRequest(`${process.env.API_URL}/classes/${id}`, preparePostRequest(body)).then(
      handleResponse
  );
}

function generateVideoS3(body) {
  return sendRequest(`${process.env.API_URL}/classes/generate-video-s3`, preparePostRequest(body)).then(
      handleResponse
  );
}

function getClassesCategories() {
  return sendRequest(`${process.env.API_URL}/classes-categories`, prepareGetRequest()).then(
      handleResponse
  );
}

function getClass(classId) {
  return sendRequest(`${process.env.API_URL}/classes/${classId}`, prepareGetRequest()).then(
      handleResponse
  );
}

function getUpcomingLiveClasses() {
  return sendRequest(`${process.env.API_URL}/classes/upcoming-live-classes`, prepareGetRequest()).then(
      handleResponse
  );
}

function fetchRecordedClasses(id, page, filters) {
  let filterParams = filters ? '&' + (new URLSearchParams(filters)).toString() : ''; // {instructor_ids: [1,2], difficulty_level_ids: [], class_type_ids: []}
  let url = `${process.env.API_URL}/classes-categories/${id}/classes?page=${page}${filterParams}`;
  return sendRequest(url, prepareGetRequest()).then(
      handleResponse
  );
}

/** @deprecated */
function publish(id) {
  let formData = new FormData();
  const requestOptions = {
    method: 'post',
    headers: {...authHeader()},
    body: formData
  };

  return sendRequest(`${process.env.API_URL}/classes/${id}/publish`, requestOptions).then(
      handleResponse
  );
}

function updateMove(moveId, body) {
  return sendRequest(`${process.env.API_URL}/classes/moves/${moveId}/update`, preparePostRequest(body)).then(
      handleResponse
  );
}

function swapOrder(body) {
  return sendRequest(`${process.env.API_URL}/classes/moves/swap-order`, preparePostRequest(body)).then(
      handleResponse
  );
}

function deactivateOrActivate(id) {
  let formData = new FormData();

  const requestOptions = {
    method: 'post',
    headers: {...authHeader()},
    body: formData
  };

  return sendRequest(`${process.env.API_URL}/classes/${id}/deactivate-or-activate`, requestOptions).then(
      handleResponse
  );
}

function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return sendRequest(`${process.env.API_URL}/classes/${id}`, requestOptions).then(
      handleResponse
  );
}

function deleteMove(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return sendRequest(`${process.env.API_URL}/classes/${id}`, requestOptions).then(
      handleResponse
  );
}