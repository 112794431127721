import axios from 'axios';
import { removeValidationsMessages, router } from './';

const apiClient = axios.create({
    baseURL: process.env.NODE_ENV === 'development'
      ? process.env.API_URL
      : location.origin,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
});

apiClient.interceptors.response.use(function (response) {
    return response
}, function (error) {
    // global error handler
    console.log(error.response.data);
    console.log(router.currentRoute.path);
    if (error.response.status === 401 && !router.currentRoute.path.startsWith('/auth')) {
        router.replace({ name: 'login' });
    }

    return Promise.reject(error);
});

let user = JSON.parse(localStorage.getItem('token'));
if (user && user.token.token) {
    setAuthHeader(user.token.token);
}

export const api = apiClient;

export function setAuthHeader(token) {
    apiClient.defaults.headers.common['X-Auth-Token'] = token;
}

export function sendRequest(url, options) {
    removeValidationsMessages();
    //BLOCK BUTTONS
    let buttons = document.querySelectorAll('button');
    buttons.forEach(function (button) {
        button.disabled = true;
    });

    return fetch(url, options).then(function (data) {
        return data;
    });
}

export const sendPostRequest = (url, params) => {
    removeValidationsMessages();

    return apiClient.post(url, params);
};

export const sendGetRequest = (url, params) => {
    return params
        ? apiClient.get(url, {params})
        : apiClient.get(url);
};

export const sendGetRequestForBlob = (url) => {
    return apiClient.get(url, { responseType: 'blob' });
};

export const sendDeleteRequest = (url) => {
    return apiClient.delete(url);
};

export const sendPutRequest = (url, data) => {
    return apiClient.put(url, data);
};

/*

function deleteMove(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return sendRequest(`${process.env.API_URL}/classes/${id}`, requestOptions).then(
      handleResponse
  );
}

*/